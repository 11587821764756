// import logo from './logo.svg';
import './App.css';
import { useState } from "react";
import { useEffect } from "react";
import simpleParallax from 'simple-parallax-js';

function App() {
  

  const [total, setTotal] = useState(0)

  const [state, setState] = useState({
    paverSquareFootage: 0,
    dirtRemoval: 500,
    grassRemoval: 0,
    jobSiteCleanUp: 250,
    dumpster: 250,
    finesBaseDelivery: 200,
    paverDelivery: 225,
    bagsOfCement: 3,
    washSandPolymeric: 1,
    demoFee: 500
  })

  const [calculations, setCalculations] = useState({
    paverCost: 0,
    bagsOfCementCost: 200,
    grassRemovalCost: 0,
    washSandPolymericCost: 100,
    palletCount: 0
  })

  useEffect(() => {

    console.log(state)
  
    let sum = parseFloat(0).toFixed(2)

    let keys = Object.keys(state)
    keys.forEach((key) => {

      switch(key) {

        case 'paverSquareFootage':
          sum = parseFloat(sum) + parseFloat(calculations.paverCost)
          break;

        case 'grassRemoval':
          sum = parseFloat(sum) + parseFloat(calculations.grassRemovalCost)
          break;

        case 'bagsOfCement':
          sum = parseFloat(sum) + parseFloat(calculations.bagsOfCementCost)
          break;

        case 'washSandPolymeric':
          sum = parseFloat(sum) + parseFloat(calculations.washSandPolymericCost)
          break;

        default:
          sum = parseFloat(sum) + parseFloat(state[key])
          break;

      }

      console.log(sum.toFixed(2))
    })
    setTotal(sum)

  }, [state, calculations])

  useEffect(() => {
    console.log(calculations)
  }, [calculations])

  const handleChange = (evt) => {
    console.log("changed")
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    })
  }

  const calcPalletCost = (evt) => {
    // let palletCost
    let palletCount = Math.ceil(evt.target.value/116)
    let paverCost = palletCount * 116 * 3
    // grassRemovalCost = evt.target.value * .60
    // console.log(grassRemovalCost)
    setCalculations({
      ...calculations,
      palletCount: palletCount,
      paverCost: paverCost
    })
  }

  const calcGrassRemoval = (evt) => {
    let grassRemovalCost
    grassRemovalCost = parseFloat(evt.target.value * .60).toFixed(2)
    console.log(grassRemovalCost)
    setCalculations({
      ...calculations,
      grassRemovalCost: grassRemovalCost
    })
  }

  const calcCementCost = (evt) => {
    let bagsOfCementCost
    if(evt.target.value > 3) {
      bagsOfCementCost = parseFloat((evt.target.value - 3)*66.66 + 200).toFixed(2)
    } else {
      bagsOfCementCost = 200
    }
    setCalculations({
      ...calculations,
      bagsOfCementCost: bagsOfCementCost
    })
  }

  const calcWashSandPolymeric = (evt) => {
    let washSandPolymericCost
    washSandPolymericCost = evt.target.value * 100
    console.log(washSandPolymericCost)
    setCalculations({
      ...calculations,
      washSandPolymericCost: washSandPolymericCost
    })
  }

  var image = document.getElementsByClassName('background-image');
  new simpleParallax(image);

  return (

    <div className="container-fluid">
      {/* <img 
        className="background-image" 
        src="https://blog.techo-bloc.com/hs-fs/hubfs/eva-dalle-de-patio-A00422_05_002_PPI.jpg?width=1200&name=eva-dalle-de-patio-A00422_05_002_PPI.jpg" 
        alt="image"/> */}
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card" style={{width: '100%'}}>
          <div class="card-header">
            <h5 className="card-title display-4">Paver Quote Calculator</h5>
          </div>
            {/* <img src="https://images.squarespace-cdn.com/content/v1/5dadce43803b170357e79380/b705bf78-6b20-42dc-9c3d-bee90e838a6b/Cambridge+2018+pg041.jpg" className="card-img-top" alt="..."/> */}
            <form onChange={handleChange} className="card-body">
              <br/>
              <h6 className="card-title display-6 text-end">Total = ${total}</h6>
              {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
              {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}

              {/* Square Footage */}
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon3">Paver sq. ft.</span>
                  <input 
                    type="number" 
                    className="form-control" 
                    value={state.paverSquareFootage} 
                    name="paverSquareFootage" 
                    aria-describedby="basic-addon3 basic-addon4"
                    onChange={calcPalletCost}
                  />
                  <span className="input-group-text" id="basic-addon3">Pallets: {calculations.palletCount}</span>
                  <span className="input-group-text" id="basic-addon3">${calculations.paverCost}</span>
                </div>
              </div>

              {/* Dirt Removal */}
              <div className="mb-3">
                <label htmlFor="basic-url" className="form-label lead">Dirt Removal</label>
                {/* <div className="input-group"> */}
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="dirtRemoval" id="dirt-removal-low" value="500" defaultChecked/>
                    <label className="form-check-label" htmlFor="dirt-removal">
                      Low - $500
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="dirtRemoval" id="dirt-removal-medium" value="800"/>
                    <label className="form-check-label" htmlFor="dirt-removal">
                      Medium - $800
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="dirtRemoval" id="dirt-removal-high" value="1500"/>
                    <label className="form-check-label" htmlFor="dirt-removal">
                      High - $1500
                    </label>
                  </div>
                {/* </div> */}
              </div>

              {/* Grass Removal */}
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon3">Grass Removal (60¢ / sq. ft.)</span>
                  <input 
                    type="number" 
                    className="form-control" 
                    name="grassRemoval" 
                    value={state.grassRemoval} 
                    aria-describedby="basic-addon3 basic-addon4"
                    onChange={calcGrassRemoval}
                  />
                  <span className="input-group-text" >${calculations.grassRemovalCost}</span>
                </div>
              </div>

              {/* Job Site Clean Up */}
              <div className="mb-3">
                <label htmlFor="basic-url" className="form-label lead">Job Site Clean Up</label>
                {/* <div className="input-group"> */}
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="jobSiteCleanUp" value="250" defaultChecked/>
                    <label className="form-check-label" htmlFor="job-site-clean-up-low">
                      Low - $250
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="jobSiteCleanUp" value="500"/>
                    <label className="form-check-label" htmlFor="job-site-clean-up-medium">
                      Medium - $500
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="jobSiteCleanUp" value="800"/>
                    <label className="form-check-label" htmlFor="job-site-clean-up-high">
                      High - $800
                    </label>
                  </div>
                {/* </div> */}
              </div>

              {/* Dumpster */}
              <div className="mb-3">
                <label htmlFor="basic-url" className="form-label lead">Dumpster</label>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="dumpster" defaultChecked disabled/>
                  <label className="form-check-label" htmlFor="dumpster" value="250">
                    $250
                  </label>
                </div>
              </div>

              {/* Fines/Base Delivery */}
              <div className="mb-3">
                <label htmlFor="basic-url" className="form-label lead">Fines/Base Delivery</label>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="200" id="fines-base-delivery" defaultChecked disabled/>
                  <label className="form-check-label" htmlFor="fines-base-delivery">
                    $200
                  </label>
                </div>
              </div>

              {/* Paver Delivery */}
              <div className="mb-3">
                <label htmlFor="basic-url" className="form-label lead">Paver Delivery</label>
                {/* <div className="input-group"> */}
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="paverDeliver" value="225" defaultChecked/>
                    <label className="form-check-label" htmlFor="paver-delivery-low">
                      Low - $225
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="paverDeliver" value="350"/>
                    <label className="form-check-label" htmlFor="paver-delivery-high">
                      High - $350
                    </label>
                  </div>
                {/* </div> */}
              </div>

              {/* Cement */}
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon3">Bags of Cement</span>
                  <input 
                    type="number" 
                    className="form-control" 
                    name="bagsOfCement" 
                    value={state.bagsOfCement} 
                    onChange={calcCementCost}
                    aria-describedby="basic-addon3 basic-addon4"
                    />
                  <span className="input-group-text" id="">${calculations.bagsOfCementCost}</span>
                </div>
              </div>

              {/* Wash Sand Polymeric */}
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon3">Wash Sand Polymeric</span>
                  <input 
                    type="number" 
                    className="form-control" 
                    name="washSandPolymeric" 
                    value={state.washSandPolymeric}
                    onChange={calcWashSandPolymeric}
                    aria-describedby="basic-addon3 basic-addon4"
                  />
                  <span className="input-group-text" id="">${calculations.washSandPolymericCost}</span>
                </div>
              </div>

              {/* Demo Fee */}
              <div className="mb-3">
                <label htmlFor="" className="form-label lead">Demo Fee</label>
                <div className="input-group">
                  <span className="input-group-text" id="">$</span>
                  <input type="number" className="form-control" name="demoFee" value={state.demoFee} aria-describedby="basic-addon3 basic-addon4"/>
                </div>
              </div>

              <h6 className="card-title display-6 text-end">Total = ${total}</h6>

            </form>
          </div>
        </div>
      </div>
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
